import lexendRegular from 'assets/fonts/lexend/lexend-regular.ttf';
import lexendMedium from 'assets/fonts/lexend/lexend-medium.ttf';
import lexendLight from 'assets/fonts/lexend/lexend-light.ttf';
import lexendBold from 'assets/fonts/lexend/lexend-bold.ttf';
import lexendSemiBold from 'assets/fonts/lexend/lexend-semibold.ttf';
import { createFontLinkWithoutPrefix } from './utils';
export const getFonts = () => {
  document.head.prepend(createFontLinkWithoutPrefix(lexendRegular));
  document.head.prepend(createFontLinkWithoutPrefix(lexendMedium));
  document.head.prepend(createFontLinkWithoutPrefix(lexendLight));
  document.head.prepend(createFontLinkWithoutPrefix(lexendBold));
  document.head.prepend(createFontLinkWithoutPrefix(lexendSemiBold));
};